<template>
  <div id="professionals-sus">
    <p class="tab-description">Configurações dos profissionais</p>

    <div class="cards-container">
      <div
        id="professional-container"
        v-for="(doctor, index) in doctors"
        :key="index"
      >
        <div class="professional-container">
          <div class="container-content">
            <div class="container-header">
              <div class="professional-description">
                <Avatar 
                  size="40"
                  :src="doctor?.avatar"                 
                />                
                <p v-if="['DOCTOR_MANAGER', 'DOCTOR', 'DOCTOR_ANESTHETIST'].includes(doctor.type)">Dr(a) {{ doctor.name }}</p>
                <p v-else-if="doctor.type === 'NURSE'">Enfermeiro(a) {{ doctor.name }}</p>
                <p v-else-if="doctor.type === 'TECHNICIAN'">Técnico(a) {{ doctor.name }}</p>
                <p v-else-if="doctor.type === 'TECHNOLOGIST'">Tecnólogo(a) {{ doctor.name }}</p>
                <p v-else> {{ doctor.name }}</p>
              </div>

              <div class="toggle-container">
                <label class="toggle-subtitle" :for="`doctor-${index}-active`">
                  {{ doctor.active ? 'Ativo' : 'Inativo' }}
                </label>
                <toggle-button
                  :id="`doctor-${index}-active`"
                  :width="40"
                  :height="24"
                  :value="doctor.active ? true : false"
                  @change="setDoctorActive(index)"
                  :color="{ checked: '#00C773' }"
                />
              </div>
            </div>

            <div
              class="container-footer"
              :class="{ hide: !doctors[index].active }"
            >
              <hr />

              <div class="footer-content">  
                <div class="row">
                  <div class="col-4">
                    <label :for="`doctor-name-${index}`">
                      Nome do profissional
                    </label>
                    <b-input
                      :id="`doctor-name-${index}`"
                      autocomplete="off"
                      :v-model="doctor.name"
                      :placeholder="doctor.name"
                      readonly
                    />
                    <div
                      v-if="validated && !doctor.name"
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>
                  <div class="col-4">
                    <label :for="`doctor-cns-${index}`">
                      CNS do profissional
                    </label>
                    <b-input
                      :id="`doctor-cns-${index}`"
                      autocomplete="off"
                      v-model="doctor.sus_professional_setting.cns"
                      :placeholder="doctor.cns"
                    />
                    <div
                      v-if="validated && !doctor.sus_professional_setting.cns"
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>
                  <div class="col-4">
                    <label :for="`doctor-cbo-${index}`">CBO</label>
                    <multiselect
                      :id="`doctor-cbo-${index}`"
                      v-model="doctor.sus_professional_setting.cbo"
                      placeholder="Selecionar"
                      :options="cbos"
                      track-by="value"
                      label="label"
                      :allow-empty="false"
                      :showLabels="false"
                      :showNoResults="false"
                      class="inline-text-container with-border"
                    >
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>
                      <template slot="singleLabel" slot-scope="{ option }">
                        <span
                          class="d-inline-block text-truncate width-85 inline-text-input"
                        >
                          {{ option.label }}
                        </span>
                      </template>
                      <template slot="noOptions"> Nenhuma opção </template>
                      <template slot="noResult"> Nenhum resultado </template>
                    </multiselect>
                    <div
                      v-if="validated && !doctor.sus_professional_setting.cbo"
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  props: {
    validated: Boolean,
    doctorsProps: Array
  },
  components: {
    Avatar: () => import('@/components/General/Avatar.vue'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    cbos: require('@/components/Tiss/Data/cbos.json'),
    doctors: [],
  }),
  mounted() {
    if (!this.doctors.length) {
      this.fillDoctors()
    }
  },
  computed: {
  },
  methods: {
    setFields() {
      const isLoading = this.$loading.show()
      this.doctors.forEach(element => {
        element.sus_professional_setting.cbo = this.cbos.find(
          option => option.value === element.sus_professional_setting.cbo
        )
      })
      isLoading.hide()
    },
    setDoctorActive(index) {
      this.doctors[index].active = !this.doctors[index].active
    },
    async getAllProfessionals() {
      const isLoading = this.$loading.show()
      this.doctors = []
      try {
        const { data } = await this.api.getAllProfessionalsWithSusSettings(
          this.clinic.id
        )
        
        data.map(professional => {
          this.doctors.push({
            professional_id: professional.id,
            name: professional.name,
            active: professional.sus_professional_setting,
            avatar: professional.picture,
            type: professional.type,
            sus_professional_setting: {
              cbo: professional.sus_professional_setting?.cbo,
              cns: professional.sus_professional_setting?.cns
            }
          })
        })

      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async fillDoctors() {
      await this.getAllProfessionals();
      this.setFields();
    }
  },
  watch: {
    'doctors': {
      async handler(value) {
        this.$emit('update-doctors-sus', this.doctors)
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
#professionals-sus {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: 'Nunito Sans';
  font-style: normal;
  color: var(--type-active);
  margin-bottom: 30px;

  .tab-description {
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #305BF2;
  }

  .inline-text-container {
    width: 100%;
  }

  .inline-text-input {
    width: 40ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    max-height: 65vh;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--neutral-300) var(--neutral-000);
  }

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: var(--neutral-000);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--neutral-300);
    border-radius: 20px;
    border: 3px solid var(--neutral-000);
  }
}

#professional-container {
  .professional-container {
    border: 1px solid var(--neutral-300);
    border-radius: 16px;

    input {
      height: 38px !important;
    }

    .container-content {
      padding: 24px;

      .hide {
        display: none;
      }

      .container-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .professional-description {
          display: flex;
          align-items: center;
          gap: 16px;

          p {
            font-weight: 700;
            font-size: 18px;
            line-height: 134%;
          }
        }

        .toggle-container {
          display: flex;
          align-items: center;
          gap: 8px;

          .toggle-subtitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: var(--dark-blue);
            margin: 0;
          }
        }
      }

      .container-footer {
        hr {
          margin: 24px 0;
          background: var(--neutral-300);
        }

        label {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: var(--dark-blue);
          }

        .footer-content {
          display: flex;
          flex-direction: column;
          gap: 16px;

          label {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: var(--dark-blue);
          }

          .inline-text {
            span {
              width: 30ch;
              text-overflow: ellipsis;
              word-wrap: unset;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .first-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
          }

          .second-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;
          }

          .third-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
          }

          .input-container {
            display: flex;
            flex-direction: column;

            input[type='text'] {
              border: 1px solid var(--neutral-300);
              border-radius: 8px;
              outline: none;
              padding: 16px;

              &:focus {
                border: 1px solid var(--blue-500);
              }
            }
          }
        }
      }
    }
  }
}
</style>
